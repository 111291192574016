var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-lg-12 comment-section" }, [
    _c("div", { staticClass: "comment-section-head" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-link btn-block text-left",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.showComments = !_vm.showComments
            }
          }
        },
        [
          _vm.showComments
            ? _c(
                "svg",
                {
                  staticClass: "bi bi-chevron-up",
                  attrs: {
                    width: "1em",
                    height: "1em",
                    viewBox: "0 0 16 16",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d:
                        "M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z",
                      "clip-rule": "evenodd"
                    }
                  })
                ]
              )
            : _c(
                "svg",
                {
                  staticClass: "bi bi-chevron-down",
                  attrs: {
                    width: "1em",
                    height: "1em",
                    viewBox: "0 0 16 16",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d:
                        "M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z",
                      "clip-rule": "evenodd"
                    }
                  })
                ]
              ),
          _vm._v(" comments (" + _vm._s(_vm.comments.length) + ") ")
        ]
      )
    ]),
    _c(
      "div",
      { class: { collapse: !_vm.showComments }, attrs: { id: "collapse" } },
      [
        _c(
          "div",
          { staticClass: "comments" },
          [
            !(_vm.comments === undefined)
              ? _c(
                  "div",
                  { staticClass: "current-comments" },
                  _vm._l(_vm.comments, function(comment) {
                    return _c(
                      "div",
                      { key: comment.id },
                      [
                        _c("Comment", { attrs: { comment: comment } }),
                        _c("hr", { staticClass: "solid" })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("AddComment", { on: { posted: _vm.postComment } })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }