var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-element" }, [
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "comment-author" }, [
        _vm._v(_vm._s(_vm.comment.who))
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _vm._v(" " + _vm._s(_vm.comment.text) + " ")
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "comment-date" }, [_vm._v(_vm._s(_vm.when))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }