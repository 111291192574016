var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle-player" }, [
    _c("audio", { ref: "myAudioTag" }, [
      _c("source", { attrs: { src: _vm.file } })
    ]),
    _c(
      "button",
      {
        staticClass: "toggle-play-button bg-primary",
        on: { click: _vm.togglePlayPause }
      },
      [
        !_vm.isPlaying
          ? _c(
              "svg",
              {
                staticClass: "bi bi-play-fill",
                attrs: {
                  width: "2.7em",
                  height: "2.7em",
                  viewBox: "0 0 16 16",
                  fill: "currentColor",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z"
                  }
                })
              ]
            )
          : _c(
              "svg",
              {
                staticClass: "bi bi-pause-fill",
                attrs: {
                  width: "2.7em",
                  height: "2.7em",
                  viewBox: "0 0 16 16",
                  fill: "currentColor",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M5.5 3.5A1.5 1.5 0 017 5v6a1.5 1.5 0 01-3 0V5a1.5 1.5 0 011.5-1.5zm5 0A1.5 1.5 0 0112 5v6a1.5 1.5 0 01-3 0V5a1.5 1.5 0 011.5-1.5z"
                  }
                })
              ]
            )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }