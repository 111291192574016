var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-auto" }, [
    _c(
      "div",
      {
        staticClass: "track",
        style: { "background-image": _vm.wavePathOfNewestVersion },
        on: {
          click: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.trackClicked($event)
          }
        }
      },
      [
        _c("div", { staticClass: "track-artist bg-secondary" }, [
          _vm._v(_vm._s(_vm.track.name))
        ]),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            d:
              "M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z",
            "clip-rule": "evenodd"
          }
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            d: "M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z",
            "clip-rule": "evenodd"
          }
        }),
        _c("div", { staticClass: "track-duration" }, [
          _vm._v(_vm._s(_vm.trackLength))
        ])
      ]
    ),
    _c("div", { staticClass: "track-info bg-secondary" }, [
      _c("div", { staticClass: "track-item add-properties" }, [
        _c("small", [_vm._v("versions:")]),
        _vm._v(" " + _vm._s(_vm.versionCount) + " ")
      ]),
      _c("div", { staticClass: "track-item add-properties" }, [_vm._v("|")]),
      _c("div", { staticClass: "track-item add-properties" }, [
        _c("small", [_vm._v("comments:")]),
        _vm._v(" " + _vm._s(_vm.commentCount) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }