var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "list-group" },
      [
        _vm._l(_vm.newVersions, function(formId, index) {
          return _c("CreateVersionForm", {
            key: formId,
            staticClass: "list-group-item",
            attrs: { id: formId, disabled: !_vm.allowUserInput },
            on: {
              close: function($event) {
                return _vm.removeVersion(index)
              }
            }
          })
        }),
        _c("addVersionElement", {
          staticClass: "list-group-item list-group-item-action",
          attrs: { type: "button", disabled: !_vm.allowUserInput },
          nativeOn: {
            mouseover: function($event) {
              $event.preventDefault()
            },
            click: function($event) {
              return _vm.addVersion($event)
            }
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }