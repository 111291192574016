var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jumbotron" }, [
      _c("h1", { staticClass: "display-4" }, [_vm._v("reHEARsal")]),
      _c("p", { staticClass: "lead" }, [
        _vm._v(
          " Please send an email to sales@rehearsal.ch to acquire an account "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }