<template>
  <div class="version-item">
    <PlayerDisplay  :version="version" />
    <VersionInfo :version="version" />
  </div>
</template>

<script>
 import PlayerDisplay from '@/components/versions/PlayerDisplay'
 import VersionInfo from '@/components/versions/VersionInfo'
 import { serverBus } from '@/main'

 export default {
   props: ['version'],
   components: {
     PlayerDisplay,
     VersionInfo,
   },
 }
</script>

<style scoped>
.version-item{
  margin-bottom: 2em;
}
</style>
