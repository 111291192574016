var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row top-row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "nav",
          {
            staticClass: "breadcrumb-header",
            attrs: { "aria-label": "breadcrumb" }
          },
          [
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/sessions/" + _vm.sessionHash + "/" } },
                    [_vm._v(" Session: " + _vm._s(_vm.sessionHash) + " ")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-chevron-right text-primary",
                      attrs: {
                        width: "1em",
                        height: "1em",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/sessions/" +
                          _vm.sessionHash +
                          "/tracks/" +
                          _vm.currentTrack.id +
                          "/"
                      }
                    },
                    [
                      _vm._v(
                        " Track: " +
                          _vm._s(
                            _vm.currentTrack ? _vm.currentTrack.name : ""
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "delete-track-button col-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger",
              on: { click: _vm.deleteTrackClicked }
            },
            [_vm._v(" Delete Track ")]
          )
        ])
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: { click: _vm.toggleAddVersionsForm }
          },
          [_vm._v("Add Version to Track")]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          class: { collapse: !_vm.showAddVersions },
          attrs: { id: "collapse" }
        },
        [_c("add-version", { attrs: { startEmpty: false } })],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [_c("version-list", { attrs: { trackId: this.$route.params.id } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }