var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-12" },
      _vm._l(_vm.successes, function(success, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "alert alert-success",
            attrs: { role: "alert" }
          },
          [_vm._v(" " + _vm._s(success) + " ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }