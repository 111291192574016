var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row top-row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "nav",
          {
            staticClass: "breadcrumb-header",
            attrs: { "aria-label": "breadcrumb" }
          },
          [
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/sessions/" + _vm.sessionHash + "/" } },
                    [_vm._v(" Session: " + _vm._s(_vm.sessionHash) + " ")]
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("h2", [
        _c("small", [_vm._v("Session:")]),
        _vm._v(" " + _vm._s(_vm.sessionHash))
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addTrackClicked($event)
            }
          }
        },
        [_vm._v("add track to session")]
      ),
      _vm.loading ? _c("p", [_vm._v("Loading...")]) : _vm._e()
    ]),
    _vm.success && !_vm.loading && _vm.getTracks.length === 0
      ? _c("div", { staticClass: "no-tracks" }, [
          _c("p", [_vm._v(" This session does not contain any tracks yet. ")])
        ])
      : _vm._e(),
    _vm.getTracks.length > 0
      ? _c(
          "div",
          _vm._l(_vm.getTracks, function(track, index) {
            return _c("app-track-list-item", {
              key: index,
              attrs: { track: track, showAddButton: index === 0 }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }