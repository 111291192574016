<template>
  <div>
    <vue-headful
        title="reHEARsal"
        description="an online tool to work on audio together" />
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="navbar-nocollapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link
                :to="`/sessions/${sessionHash}/`"
                class="nav-link"
                active-class="active"
            >Track list</router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="`/sessions/${sessionHash}/addTrack`"
                class="nav-link"
                active-class="active"
            >Upload track</router-link>
          </li>
          <li class="nav-item active mx-auto">
            <router-link
                :to="`/sessions/${sessionHash}/`"
                class="nav-link"
                active-class="active"
            >reHEARsal: {{sessionHash}}</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container">
      <app-messages></app-messages>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
 import Messages from './components/general/Messages'
 import { mapGetters } from 'vuex'
 import * as types from './store/types'
 import Vue from 'vue'
 import vueHeadful from 'vue-headful'

 Vue.component('vue-headful', vueHeadful);

 export default {
     components: {
         'app-messages': Messages,
     },
     computed: {
         ...mapGetters({ sessionHash: types.CURRENT_SESSION_HASH }),
     },
 }
</script>
<style scoped></style>
