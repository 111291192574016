<template>
  <div>
    <div class="row top-row">
      <div class="col">
        <nav class="breadcrumb-header" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="`/sessions/${sessionHash}/`">
                Session: {{ sessionHash }}
              </router-link>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <h2><small>Session:</small> {{sessionHash}}</h2>
    </div>
    <div class="row">
      <button class="btn btn-primary"
              @click.prevent="addTrackClicked" >add track to session</button>
      <p v-if="loading">Loading...</p>
    </div>
    <div class="no-tracks" v-if="success && !loading && getTracks.length === 0">
      <p> This session does not contain any tracks yet. </p>
    </div>
    <div v-if="getTracks.length > 0">
      <app-track-list-item
        v-for="(track, index) in getTracks"
        :track="track"
        :showAddButton="index === 0"
        :key="index"></app-track-list-item>
    </div>
  </div>
</template>

<script>
 import * as types from '../../store/types.js'
 import { mapGetters } from 'vuex'
 import TrackListItem from './TrackListItem.vue'

 export default {
   computed: {
     ...mapGetters({
       success: types.SUCCESS,
       loading: types.LOADING,
       getTracks: types.TRACKS_SORTED,
       sessionHash: types.CURRENT_SESSION_HASH,
     }),
   },
   components: {
     'app-track-list-item': TrackListItem,
   },
   methods: {
     addTrackClicked() {
       this.$router.push({ name: 'addTrack' })
     },
   }
 }
</script>
