<template>
  <div class="version-info-and-comments">
    <div class="info-head row">
      <div class="version-info col-lg-8">
        <small>{{version.info}}</small>
      </div>
      <div class="version-created col-lg-4 bg-secondary text-right">
        <small>{{ `created ${daysPassedSince()} days ago` }}</small>
      </div>
    </div>
    <div class="row">
      <CommentSection :version="version" />
    </div>
  </div>
</template>

<script>
 import CommentSection from '@/components/comments/CommentSection'
 import { mapGetters, mapActions } from 'vuex'
 import * as types from '@/store/types.js'
 import { ApiClient } from '@/api/server/index'

 export default {
   components: { CommentSection },
   props: ['version'],
   methods: {
     daysPassedSince() {
       const mydate = new Date(this.version.date_created)
       return Math.floor((Date.now() - mydate.getTime()) / (1000 * 60 * 60 * 24))
     },
   },
 }
</script>

<style scoped>
.version-info-and-comments {
  width: 700px;
}
.info-head {
  background-color: #6c757d;
  color: #fff;
}
</style>
