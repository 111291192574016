var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-12" },
      _vm._l(_vm.errors, function(error, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "alert alert-danger",
            attrs: { role: "alert" },
            on: {
              click: function($event) {
                return _vm.onMessageClick(index)
              }
            }
          },
          [_vm._v(" " + _vm._s(error) + " ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }