var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", {
        attrs: {
          title: "reHEARsal",
          description: "an online tool to work on audio together"
        }
      }),
      _c(
        "nav",
        { staticClass: "navbar navbar-expand-lg navbar-dark bg-primary" },
        [
          _c(
            "div",
            {
              staticClass: "navbar-nocollapse",
              attrs: { id: "navbarSupportedContent" }
            },
            [
              _c("ul", { staticClass: "navbar-nav" }, [
                _c(
                  "li",
                  { staticClass: "nav-item active" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: "/sessions/" + _vm.sessionHash + "/",
                          "active-class": "active"
                        }
                      },
                      [_vm._v("Track list")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: "/sessions/" + _vm.sessionHash + "/addTrack",
                          "active-class": "active"
                        }
                      },
                      [_vm._v("Upload track")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "nav-item active mx-auto" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: "/sessions/" + _vm.sessionHash + "/",
                          "active-class": "active"
                        }
                      },
                      [_vm._v("reHEARsal: " + _vm._s(_vm.sessionHash))]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "container" },
        [_c("app-messages"), _c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }