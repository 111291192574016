var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          {
            staticClass: "version",
            style: { "background-image": _vm.wavePath },
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.onBackgroundlick($event)
              }
            }
          },
          [
            _c("app-play-pause-player", {
              ref: "player",
              staticClass: "pp-player",
              attrs: { file: _vm.audioFilePath }
            }),
            _c("app-play-marker", {
              ref: "marker",
              attrs: { version: _vm.version, playerFn: _vm.playerFn }
            }),
            _c("div", { staticClass: "version-duration" }, [
              _vm._v(_vm._s(_vm.trackLength))
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }