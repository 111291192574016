var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "version-info-and-comments" }, [
    _c("div", { staticClass: "info-head row" }, [
      _c("div", { staticClass: "version-info col-lg-8" }, [
        _c("small", [_vm._v(_vm._s(_vm.version.info))])
      ]),
      _c(
        "div",
        { staticClass: "version-created col-lg-4 bg-secondary text-right" },
        [
          _c("small", [
            _vm._v(_vm._s("created " + _vm.daysPassedSince() + " days ago"))
          ])
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "row" },
      [_c("CommentSection", { attrs: { version: _vm.version } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }