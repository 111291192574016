<template>
  <div>
    <div class="row top-row">
      <div class="col">
        <nav class="breadcrumb-header" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="`/sessions/${sessionHash}/`">
                Session: {{ sessionHash }}
              </router-link>
            </li>
            <li>
              <svg class="bi bi-chevron-right text-primary"
                   width="1em"
                   height="1em"
                   viewBox="0 0 16 16" 
                   fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg" >
                <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
              <router-link :to="`/sessions/${sessionHash}/tracks/${currentTrack.id}/`">
                Track: {{ currentTrack ? currentTrack.name : '' }}
              </router-link>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row" >
      <div class="col-6">
        <div class="delete-track-button col-6">
          <button @click="deleteTrackClicked" class="btn btn-danger">
            Delete Track
          </button>
        </div>
      </div>
      <div class="col-6" >
        <button class="btn btn-primary" @click="toggleAddVersionsForm">Add Version to Track</button>
      </div>
    </div>

    <div class="row">
      <div id="collapse" :class="{ collapse: !showAddVersions }">
        <add-version :startEmpty="false" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <version-list :trackId="this.$route.params.id"></version-list>
      </div>
    </div>
  </div>
</template>

<script>
 import * as types from '../store/types.js'
 import { mapGetters } from 'vuex'
 import { mapActions } from 'vuex'
 import VersionList from '../components/versions/VersionList.vue'
 import { serverBus } from '../main'
 import { sessionMixin } from '../components/sessions/sessionMixin'
 import AddVersion from '@/views/AddVersion'

 export default {
   mixins: [sessionMixin],
   data() {
     return {
       showAddVersions: false,
     }
   },
   computed: {
     ...mapGetters({
       currentTrack: types.CURRENT_TRACK,
       sessionHash: types.CURRENT_SESSION_HASH,
     }),
   },
   methods: {
     ...mapActions({
       deleteTrack: types.DELETE_TRACK,
       removeTrackFromStore: types.DELETE_TRACK_FROM_STORE,
       initStore: types.INIT_STORE,
       setCurrentTrackById: types.SET_CURRENT_TRACK_BY_ID,
     }),
     deleteTrackClicked() {
       const trackId2Delete = this.$store.currentTrack.id
       this.deleteTrack(trackId2Delete).then(trackWasDeleted => {
         if (trackWasDeleted) {
           serverBus.$emit(
             'add-success-message',
             `track: ${this.getTrackById.name} was successfully deleted`
           )
           this.removeTrackFromStore(trackId2Delete)
           this.$router.push(`/sessions/${this.sessionHash}/`)
         } else {
           serverBus.$emit(
             'add-error-message',
             'error while deleting track! status code was not 200!'
           )
         }
       }).catch(error => {
         serverBus.$emit('add-error-message', error)
       })
     },
     toggleAddVersionsForm(){
       this.showAddVersions = !this.showAddVersions;
     },
   },
   components: {
     'add-version': AddVersion,
     'version-list': VersionList,
   },
   mounted() {
     if (! this.$store.currentTrack) {
       console.log('initializing store')
       this.initStore().then(() =>
         this.setCurrentTrackById(this.$route.params.id)
       )
     }
   },
 }
</script>

<style scoped>
.breadcrumb-header {
  margin-top: 10px;
}

.top-row {
  position: relative;
}
</style>
