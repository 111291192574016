var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "add-track-form" } }, [
    _c("form", [
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "trackName" }
          },
          [_vm._v("Name")]
        ),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.trackName,
                expression: "trackName"
              }
            ],
            class: ["form-control", { "is-invalid": _vm.formIsInvalid }],
            attrs: { id: "trackName" },
            domProps: { value: _vm.trackName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.trackName = $event.target.value
              }
            }
          }),
          _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v(" Please enter a name for your track ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }