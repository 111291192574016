<template>
  <div class="comment-element">
    <div class="row">
      <span class="comment-author">{{ comment.who }}</span>&nbsp; 
    </div>
    <div class="row">
      {{ comment.text }}
    </div>
    <div class="row">
      <span class="comment-date" >{{ when }}</span>
    </div>
  </div>
</template>

<script>
 export default {
   name: 'CommentPost',
   props: { comment: Object },
   computed: {
     when() {
       let comment_date = new Date(this.comment.when);
       let minutes_ago = Math.floor((Date.now() - comment_date)/60000); // difference in minutes
       let unit = "";
       if (minutes_ago < 1){
         return "just now"
       } else if (minutes_ago < 60) {
         if (minutes_ago>1)
           unit = " minutes ";
         else
           unit = " minute ";
         return "about " + minutes_ago + unit + "ago"
       }
       let hours_ago = Math.floor(minutes_ago/60);
       if (hours_ago < 24) {
         if (hours_ago >1)
           unit = " hours "
         else
           unit = " hour "
         return "about " + hours_ago + unit + "ago"
       } 
       let days_ago = hours_ago/24;
       if (days_ago > 1)
         unit = " days ";
       else
         unit = " day ";
       return  days_ago + unit + "ago"
     },
   },
 }
</script>

<style scoped>
.comment-author, .comment-date{
  font-size: 0.6em;
}
</style>
