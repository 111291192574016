var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Create Track")]),
      _c("CreateTrackForm"),
      _c("h2", [_vm._v("Versions")]),
      _c("AddVersionsForm", { attrs: { startEmpty: false } }),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: { click: _vm.createTrack }
        },
        [_vm._v(" Create Track ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }