var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addVersionElement" }, [
    _c("span", { staticClass: "round-plus-sign" }, [
      _c("button", { staticClass: "round-button bg-primary" }, [
        _c(
          "svg",
          {
            staticClass: "bi bi-plus",
            attrs: {
              width: "1.5em",
              height: "1.5em",
              viewBox: "0 0 16 16",
              fill: "currentColor",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                d:
                  "M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z",
                "clip-rule": "evenodd"
              }
            }),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                d:
                  "M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z",
                "clip-rule": "evenodd"
              }
            })
          ]
        )
      ])
    ]),
    _c("label", [_vm._v("Add new Version")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }