var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showIcon
          ? _c("div", [
              _vm.type === "success"
                ? _c("div", { staticClass: "upload success" }, [
                    _c("span", { staticClass: "sign bg-success" }, [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "bi bi-check d-flex justify-content-center",
                          attrs: {
                            width: "2em",
                            height: "2em",
                            viewBox: "0 0 16 16",
                            fill: "white",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d:
                                "M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z",
                              "clip-rule": "evenodd"
                            }
                          })
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.type === "error"
                ? _c("div", { staticClass: "upload error" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "sign bg-danger d-flex justify-content-center"
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-exclamation",
                            attrs: {
                              width: "2em",
                              height: "2em",
                              viewBox: "0 0 16 16",
                              fill: "white",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M7.002 11a1 1 0 112 0 1 1 0 01-2 0zM7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }