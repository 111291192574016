var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row justify-content-end" }, [
      _c("div", { staticClass: "col-sm-1" }, [
        _c(
          "div",
          {
            staticClass: "remove-button float-right",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "bi bi-x-circle-fill",
                attrs: {
                  width: "1em",
                  height: "1em",
                  viewBox: "0 0 16 16",
                  fill: "currentColor",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d:
                      "M16 8A8 8 0 110 8a8 8 0 0116 0zm-4.146-3.146a.5.5 0 00-.708-.708L8 7.293 4.854 4.146a.5.5 0 10-.708.708L7.293 8l-3.147 3.146a.5.5 0 00.708.708L8 8.707l3.146 3.147a.5.5 0 00.708-.708L8.707 8l3.147-3.146z",
                    "clip-rule": "evenodd"
                  }
                })
              ]
            )
          ]
        )
      ])
    ]),
    _c("form", [
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "customFile" }
          },
          [_vm._v("Audio File")]
        ),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("div", { staticClass: "custom-file" }, [
            _c("input", {
              class: [
                "custom-file-input",
                { "is-invalid": _vm.audioFileInputIsInvalid }
              ],
              attrs: { type: "file", id: "customFile", disabled: _vm.disabled },
              on: {
                change: function($event) {
                  return _vm.processFile($event)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "custom-file-label",
                attrs: { for: "customFile" }
              },
              [_vm._v(_vm._s(_vm.vFile.name))]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "versionInfoTextArea" }
          },
          [_vm._v("Version Info")]
        ),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.vInfo,
                expression: "vInfo"
              }
            ],
            class: [
              "form-control",
              { "is-invalid": _vm.versionInfoInputIsInvalid }
            ],
            attrs: { id: "versionInfoTextArea", disabled: _vm.disabled },
            domProps: { value: _vm.vInfo },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.vInfo = $event.target.value
              }
            }
          }),
          _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v(" Add some information about this version ")
          ])
        ])
      ])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.uploading,
            expression: "uploading"
          }
        ],
        staticClass: "uploading"
      },
      [_vm._m(0)]
    ),
    _vm.versionUploaded
      ? _c(
          "div",
          { staticClass: "upload-success" },
          [_c("ResponseIcon", { attrs: { type: "success", showTime: "700" } })],
          1
        )
      : _vm._e(),
    _vm.error
      ? _c(
          "div",
          { staticClass: "upload-error" },
          [_c("ResponseIcon", { attrs: { type: "error", showTime: "700" } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }