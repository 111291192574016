<template>
  <div class="container">
    <h2>Add Version(s) to {{ currentTrack.name }}</h2>
    <AddVersionsForm :startEmpty="false" />
    <button @click="uploadVersions" type="button" class="btn btn-primary">
      Uploading Version(s)
    </button>
  </div>
</template>

<script>
 import { mapActions, mapGetters } from 'vuex'
 import AddVersionsForm from '@/components/forms/AddVersionsForm'
 import { sessionMixin } from '@/components/sessions/sessionMixin'
 import * as types from '@/store/types.js'

 export default {
   mixins: [sessionMixin],
   components: { AddVersionsForm },

   methods: {
     ...mapActions({
       addVersion: types.ADD_VERSION,
       setCurrentVersion: types.SET_CURRENT_VERSION,
       setCurrentProgress: types.SET_CREATE_TRACK_CURRENT_STATE,
       initStore: types.INIT_STORE,
     }),

     uploadVersions() {
       this.setCurrentProgress('SUBMIT_VERSIONS');
     },
   },

   computed: {
     ...mapGetters({
       createTrackProgress: types.CREATE_TRACK_CURRENT_STATE,
       currentTrack: types.CURRENT_TRACK,
     }),
   },
   watch: {
     createTrackProgress(progress) {
       console.log(progress)
       switch (progress) {
         case 'TRACK_CREATED':
           this.setCurrentProgress('SUBMIT_VERSIONS');
           break
         case 'SUBMIT_TRACK_FAILED':
           console.log('submit track failed');
           // fall through
         case 'ALL_VERSIONS_UPLOADED':
           this.setCurrentProgress('USER_INPUT');
           this.$router.push(`/sessions/${this.sessionHash}/tracks/${currentTrack.id}`);
           break
       }
     },
   },
   mounted() {
     this.setCurrentProgress('USER_INPUT');
     if (!this.tracks || this.tracks.length === 0) {
       this.initStore()
     }
   },
 }
</script>
<style scoped>
.list-group {
  padding: 2% 0%;
}
</style>
