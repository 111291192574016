import { mapGetters } from 'vuex'
import * as types from '../../store/types'

export const sessionMixin = {
  computed: {
    ...mapGetters({ sessionHash: types.CURRENT_SESSION_HASH }),
  },
  created() {
    if (!this.sessionHash ||
        this.$route.params.hash != this.sessionHash) {
      this.$store.dispatch(types.SET_CURRENT_SESSION, this.$route.params.hash)
    }
  },
}
