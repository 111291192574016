var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-messages" } },
    [
      _c("app-error-messages", {
        attrs: {
          errors: _vm.errorMessages,
          deleteErrorFn: _vm.deleteErrorMessage
        }
      }),
      _c("app-success-messages", { attrs: { successes: _vm.successMessages } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }