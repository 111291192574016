<template>
  <div>
    <div class="form-group row">
      <div class="col-6">
        <input type="text" class="form-control" v-model="author" placeholder="Name" />
        <button class="btn btn-primary" @click="postComment">
          Add Comment
        </button>
      </div>
      <div class="col-6">
        <textarea
          class="form-control"
          type="text"
          v-model="content"
          placeholder="Comment..."></textarea>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   data() {
     return {
       author: '',
       content: '',
     }
   },

   methods: {
     postComment() {
       this.$emit('posted', this.author, this.content)
       this.author = ''
       this.content = ''
     },
   },
 }
</script>

<style scoped>
.comment-form {
  padding-bottom: 0.5em;
}
* {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
textarea {
  border: 1px solid #ccc;
}
input {
  border: 1px solid #ccc;
}
</style>
