var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "play" },
      on: { "before-enter": _vm.beforeEnter, enter: _vm.enter }
    },
    [
      _vm.isPlaying || _vm.animationRunning
        ? _c("div", { staticClass: "play-position-marker" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }