var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.author,
              expression: "author"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Name" },
          domProps: { value: _vm.author },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.author = $event.target.value
            }
          }
        }),
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.postComment } },
          [_vm._v(" Add Comment ")]
        )
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Comment..." },
          domProps: { value: _vm.content },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.content = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }